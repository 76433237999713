import React, { useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getErrorMessage, validateEmail } from '../utils'
import Axios from '../config'
import swal from 'sweetalert'
import Loader from './Loader'
import { Link } from 'react-router-dom'
const FrontEndFooter = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState('')
  const [emailError, setEmailError] = useState('')
  const emailInputRef = useRef(null)

  const handleEmailChange = e => {
    setEmail(e.target.value)
    setEmailError('')
  }

  const handleSubscribe = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      let isError = false
      if (email.trim() === '') {
        setEmailError('Please enter email address')
        emailInputRef.current.focus() // Focus on the input element with the validation error
        return (isError = true)
      }

      if (!validateEmail(email.trim())) {
        setEmailError('Please input a valid email address')
        emailInputRef.current.focus() // Focus on the input
        return (isError = true)
      }

      const { data } = await Axios.post('/subscribers/subscribe', { email })

      if (data) {
        swal('Great', 'Newsletter Subscription Successful', 'success').then(() => {
          // Once the swal dialog is closed, empty email input
          return setEmail('')
        })
      }

      setLoading(false)
    } catch (error) {
      swal('Oops', getErrorMessage(error), 'error')
      setLoading(false)
    }
  }

  return (
    <div className='container'>
      <footer className='mt-5'>
        <div className='row'>
          <div className='col-6 col-md-2 mb-3'>
            <h5>Company</h5>
            <ul className='nav flex-column'>
              <li className='nav-item mb-2'>
                <Link
                  to='/about'
                  className='nav-link p-0 text-body-secondary'
                >
                  About
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/conditions-of-carriage'
                  className='nav-link p-0 text-body-secondary'
                >
                  Conditions of Carriage
                </Link>
              </li>
            </ul>
          </div>

          <div className='col-6 col-md-2 mb-3'>
            <h5>Support</h5>
            <ul className='nav flex-column'>
              <li className='nav-item mb-2'>
                <Link
                  to='/faq'
                  className='nav-link p-0 text-body-secondary'
                >
                  Frequently Asked Questions
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/service-news'
                  className='nav-link p-0 text-body-secondary'
                >
                  Service News
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/contact-us'
                  className='nav-link p-0 text-body-secondary'
                >
                  Contact Us
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/report-fraud'
                  className='nav-link p-0 text-body-secondary'
                >
                  Report Fraud
                </Link>
              </li>
            </ul>
          </div>

          <div className='col-6 col-md-2 mb-3'>
            <h5>Customer</h5>
            <ul className='nav flex-column'>
              <li className='nav-item mb-2'>
                <Link
                  to='/ship'
                  className='nav-link p-0 text-body-secondary'
                >
                  Ship
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/locations'
                  className='nav-link p-0 text-body-secondary'
                >
                  Locations
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/track-order'
                  className='nav-link p-0 text-body-secondary'
                >
                  Track Order
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/register/user'
                  className='nav-link p-0 text-body-secondary'
                >
                  Register
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link
                  to='/login/user'
                  className='nav-link p-0 text-body-secondary'
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>

          <div className='col-md-5 offset-md-1 mb-3'>
            <form>
              <h5>Subscribe to our newsletter</h5>
              <p style={{ textAlign: 'justify' }}>
                Monthly digest of what's new and exciting from us.
              </p>
              <div className='d-flex flex-column flex-sm-row w-100 gap-2'>
                <label for='newsletter' className='visually-hidden'>
                  Email address
                </label>
                <div className='input-group has-validation'>
                  <span className='input-group-text'>
                    <i className='bi bi-envelope-at'></i>
                  </span>
                  <input
                    id='newsletter'
                    type='email'
                    className={`form-control ${emailError ? 'is-invalid' : ''
                      } ${email ? 'is-valid' : ''}`}
                    placeholder='Email address'
                    required
                    onChange={e => {
                      handleEmailChange(e)
                    }}
                    ref={emailInputRef}
                  />
                  <div className='invalid-feedback'>{emailError}</div>
                </div>
                {loading && <Loader />}
                <button
                  className='btn btn-success shadow'
                  onClick={e => handleSubscribe(e)}
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={`d-flex flex-column flex-sm-row justify-content-between p-2 mb-3 mt-4 border-top ${isTabletOrMobile ? 'justify-content-center align-items-center' : ''
            }`}
        >
          <p>
            {new Date().getFullYear()} © Ficoven Express LTD | Crafted with{' '}
            <i className='bi bi-heart text-danger'></i> by{' '}
            <a
              href='https://omimek.com'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: 'green' }}
            >
              Omimek Technology
            </a>
          </p>
          <ul
            className={`list-unstyled d-flex ${isTabletOrMobile
                ? 'justify-content-center'
                : 'justify-content-start'
              }`}
          >
            <li className='ms-3'>
              <a className='link-body-emphasis' href='#'>
                <i className='bi bi-twitter-x' style={{ color: 'green' }}></i>
              </a>
            </li>
            <li className='ms-3'>
              <a className='link-body-emphasis' href='#'>
                <i className='bi bi-instagram' style={{ color: 'green' }}></i>
              </a>
            </li>
            <li className='ms-3'>
              <a className='link-body-emphasis' href='#'>
                <i className='bi bi-facebook' style={{ color: 'green' }}></i>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default FrontEndFooter
