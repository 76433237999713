import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css' // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js' // Import Bootstrap JavaScript
import React, { useEffect, Suspense, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import BackToTopButton from './components/BackToTopButton'
import BackEndLayout from '../src/layouts/BackEndLayout'
import FrontEndLayout from '../src/layouts/FrontEndLayout'
import FullScreenLoader from './components/FullScreenLoader'
import Swal from 'sweetalert2'

import '../node_modules/datatables.net/js/dataTables.min.js'
// import '../node_modules/datatables.net/js/jquery.dataTables.min.js'
// import '../node_modules/datatables.net/js/dataTables.start.js'
import '../node_modules/datatables.net-bs4/js/dataTables.bootstrap4.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.html5.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.print.min.js'
import '../node_modules/datatables.net-buttons/js/dataTables.buttons.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.colVis.min.js'
import '../node_modules/datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js'
import '../node_modules/datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js'
import '../node_modules/datatables.net-dt/js/dataTables.dataTables.min.js'
import '../node_modules/datatables.net-responsive/js/dataTables.responsive.min.js'
import '../node_modules/jszip/dist/jszip.min.js'
import '../node_modules/pdfmake/build/pdfmake.min.js'
import '../node_modules/pdfmake/build/vfs_fonts.js'

import ProtectedRoute from './layouts/ProtectedRoute.js'
import { useAuthRedirect, useAutoLogout } from './libs/auth/index.js'

//using lazy load
const HomeScreen = lazy(() => import('./views/frontend/HomeScreen'))
const ErrorPage = lazy(() => import('./components/ErrorPage'))
const UnauthorizedPage = lazy(() => import('./components/UnauthorizedPage'))
const AboutUs = lazy(() => import('./views/frontend/about/AboutUs'))
const Faq = lazy(() => import('./views/frontend/faq/Faq'))
const Ship = lazy(() => import('./views/frontend/ship/Ship'))
const ShipPaymentSuccess = lazy(() => import('./views/frontend/ship/ShipPaymentSuccess'))
const GetQuote = lazy(() => import('./views/frontend/quote/GetQuote'))
const RegisterPageUser = lazy(() => import('./views/auth/RegisterPageUser'))
const VerifyEmail = lazy(() => import('./views/auth/VerifyEmail'))
const RegisterPageDriver = lazy(() => import('./views/auth/RegisterPageDriver'))
const RegisterPageWarehouseAgent = lazy(() =>
  import('./views/auth/RegisterPageWarehouseAgent')
)
const LoginPageUser = lazy(() => import('./views/auth/LoginPageUser'))
const ForgotPassword = lazy(() => import('./views/auth/ForgotPassword'))
// const LoginPageDriver = lazy(() => import('./views/auth/LoginPageDriver'))
const MyOrders = lazy(() => import('./views/frontend/order/MyOrders'))
const OrderDetails = lazy(() => import('./views/frontend/order/OrderDetails'))
const ReviewOrder = lazy(() => import('./views/frontend/order/ReviewOrder'))
const MyAccount = lazy(() => import('./views/frontend/account/MyAccount'))
const TrackMyOrder = lazy(() => import('./views/frontend/track/TrackMyOrder'))
const ServiceNews = lazy(() =>
  import('./views/frontend/service-news/ServiceNews')
)
const ContactUs = lazy(() => import('./views/frontend/contact/ContactUs'))
const Locations = lazy(() => import('./views/frontend/locations/Locations'))
const LocationWithinCountry = lazy(() =>
  import('./views/frontend/locations/LocationWithinCountry')
)
const ReportFraud = lazy(() =>
  import('./views/frontend/trust-center/ReportFraud')
)
const ConditionsOfCarriage = lazy(() =>
  import('./views/frontend/conditions-of-carriage/ConditionsOfCarriage')
)

// backend
const Dashboard = lazy(() => import('./views/backend/Dashboard.js'))
const ScanBarcode = lazy(() => import('./views/backend/barcode/ScanBarcode.js'))
const OrderDetailsBarcode = lazy(() => import('./views/backend/barcode/OrderDetailsBarcode.js'))
const DepotInventoryDetailsBarcode = lazy(() => import('./views/backend/barcode/DepotInventoryDetailsBarcode.js'))
const ReturnDetailsBarcode = lazy(() => import('./views/backend/barcode/ReturnDetailsBarcode.js'))
const OutboundDetailsBarcode = lazy(() => import('./views/backend/barcode/OutboundDetailsBarcode.js'))
const AllPartners = lazy(() => import('./views/backend/partner/AllPartners.js'))
const AddPartner = lazy(() => import('./views/backend/partner/AddPartner.js'))
const EditPartner = lazy(() => import('./views/backend/partner/EditPartner.js'))
const AllUsers = lazy(() => import('./views/backend/user/AllUsers.js'))
const AllWarehouseAgents = lazy(() =>
  import('./views/backend/user/AllWarehouseAgents.js')
)
const AllAdminUsers = lazy(() =>
  import('./views/backend/user/AllAdminUsers.js')
)
const AllDrivers = lazy(() => import('./views/backend/user/AllDrivers.js'))
const EditUser = lazy(() => import('./views/backend/user/EditUser.js'))
const AllSubscribers = lazy(() =>
  import('./views/backend/newsletter/AllNewsletter.js')
)
const AllContactMessages = lazy(() =>
  import('./views/backend/contact/AllContact.js')
)
const AllFaq = lazy(() => import('./views/backend/faq/AllFaq.js'))
const AllFaqCategories = lazy(() =>
  import('./views/backend/faq/AllFaqCategory.js')
)
const AddFaqCategory = lazy(() =>
  import('./views/backend/faq/AddFaqCategory.js')
)

const AddFaq = lazy(() => import('./views/backend/faq/AddFaq.js'))

const AllServiceNewsCategories = lazy(() =>
  import('./views/backend/service-news/AllServiceNewsCategory.js')
)

const AllServiceNews = lazy(() =>
  import('./views/backend/service-news/AllServiceNews.js')
)

const AddServiceNewsCategory = lazy(() =>
  import('./views/backend/service-news/AddServiceNewsCategory.js')
)

const AddServiceNews = lazy(() =>
  import('./views/backend/service-news/AddServiceNews.js')
)

const AllLocation = lazy(() =>
  import('./views/backend/location/AllLocation.js')
)

const AllContinent = lazy(() =>
  import('./views/backend/location/AllContinent.js')
)

const AddContinent = lazy(() =>
  import('./views/backend/location/AddContinent.js')
)

const AddLocation = lazy(() =>
  import('./views/backend/location/AddLocation.js')
)

const AllEvent = lazy(() => import('./views/backend/event/AllEvent.js'))

const AddEvent = lazy(() => import('./views/backend/event/AddEvent.js'))

const AllSlider = lazy(() => import('./views/backend/slider/AllSlider.js'))

const AddSlider = lazy(() => import('./views/backend/slider/AddSlider.js'))

const AllCarrier = lazy(() => import('./views/backend/carrier/AllCarrier.js'))

const AddCarrier = lazy(() => import('./views/backend/carrier/AddCarrier.js'))

const AllDepot = lazy(() => import('./views/backend/depot/AllDepot.js'))

const AllDepotInventory = lazy(() =>
  import('./views/backend/depot-inventory/AllDepotInventory.js')
)

const AddDepot = lazy(() => import('./views/backend/depot/AddDepot.js'))

const AddDepotInventory = lazy(() =>
  import('./views/backend/depot-inventory/AddDepotInventory.js')
)

const AllShipper = lazy(() => import('./views/backend/shipper/AllShipper.js'))

const AddShipper = lazy(() => import('./views/backend/shipper/AddShipper.js'))

const AllQuotes = lazy(() => import('./views/backend/quote/AllQuotes.js'))

const EditQuote = lazy(() => import('./views/backend/quote/EditQuote.js'))

const AllVehicles = lazy(() => import('./views/backend/vehicle/AllVehicles.js'))

const AddVehicle = lazy(() => import('./views/backend/vehicle/AddVehicle.js'))

const AllOrders = lazy(() => import('./views/backend/order/AllOrders.js'))
const AllOrderInventories = lazy(() =>
  import('./views/backend/order-inventory/AllOrderInventories.js')
)
const AllInbound = lazy(() => import('./views/backend/inbound/AllInbound.js'))
const AllOutbound = lazy(() =>
  import('./views/backend/outbound/AllOutbound.js')
)
const AllReturns = lazy(() => import('./views/backend/returns/AllReturns.js'))
const AddReturn = lazy(() => import('./views/backend/returns/AddReturn.js'))
const EditReturn = lazy(() => import('./views/backend/returns/EditReturn.js'))
const EditOutbound = lazy(() =>
  import('./views/backend/outbound/EditOutbound.js')
)
const EditInbound = lazy(() =>
  import('./views/backend/inbound/EditInbound.js')
)

const EditOrder = lazy(() => import('./views/backend/order/EditOrder.js'))

const AdminOrderDetails = lazy(() =>
  import('./views/backend/order/AdminOrderDetails.js')
)

const AdminAssignDriver = lazy(() =>
  import('./views/backend/order/AdminAssignDriver.js')
)

const AllReview = lazy(() => import('./views/backend/review/AllReview.js'))

const MyOrdersDriver = lazy(() =>
  import('./views/backend/order/MyOrdersDriver.js')
)

const OrderDetailsDriver = lazy(() =>
  import('./views/backend/order/OrderDetailsDriver.js')
)

const OrderStartTripDriver = lazy(() =>
  import('./views/backend/order/OrderStartTripDriver.js')
)

const AllRoutes = lazy(() => import('./views/backend/route/AllRoutes.js'))

const RouteDetails = lazy(() => import('./views/backend/route/RouteDetails.js'))

const App = () => {
  useAuthRedirect() // custom hook is responsible for checking the user's authentication status and role and handle redirection to home page from login (already logged in users) and registration (users with certain 'role' can't access the registration) pages.

  useAutoLogout() // A custom hook that checks the token's expiration time every second and logs out the user if the token is expired. It displays a SweetAlert notification and navigates the user to the login page.

  useEffect(() => {
    //set localStorage value
    const hasConsent = localStorage.getItem('cookieConsent')
    // Simulate some delay to show the loader
    if (!hasConsent) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout)
        showConsentModal()
        // Finish loading, hide loader
      }, 3000) // Adjust the duration as needed

      return () => clearTimeout(timeout)
    }
  }, [])

  const showConsentModal = () => {
    Swal.fire({
      position: 'bottom',
      width: '100%',
      background: 'rgba(0, 0, 0, 0.8)',
      title: 'Cookie Disclaimer',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#006400',
      denyButtonText: `Don't save`,
      html: `
        <div role="alertdialog" aria-describedby="onetrust-policy-text" aria-modal="true" aria-label="Privacy" style="display: flex; align-items: center; justify-content: center; height: 100%;">
          <div className="ot-sdk-container" style="width: 80%; max-width: 800px;">
            <div className="ot-sdk-row">
              <div id="onetrust-group-container" className="ot-sdk-eight ot-sdk-columns">
                <div className="banner_logo"></div>
                <div id="onetrust-policy" style="color: white;">
                  <div id="onetrust-policy-text">
                    This website uses cookies and similar technologies, (hereafter “technologies”), which enable us, for example, to determine how frequently our internet pages are visited, the number of visitors, to configure our offers for maximum convenience and efficiency and to support our marketing efforts. These technologies incorporate data transfers to third-party providers based in countries without an adequate level of data protection (e. g. United States). For further information, including the processing of data by third-party providers and the possibility of revoking your consent at any time, please see your settings under “Consent Preferences” and our
                    <a className="ot-cookie-policy-link" href="#" target="_blank" aria-label="Privacy notice, opens in a new tab" style="color: white;">Privacy notice</a>
                    <a className="ot-imprint-link" href="#" target="_blank" aria-label="Legal Notice, opens in a new tab" style="color: white;">Legal Notice</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        localStorage.setItem('cookieConsent', true)
        Swal.fire({
          title: 'Saved!',
          text: '', // Empty text for success message (optional)
          icon: 'success',
          confirmButtonColor: '#006400' // Set the background color to green
        })
      } else if (result.isDenied) {
        Swal.fire({
          title: 'Oops!',
          text: 'Changes are not saved', // Empty text for success message (optional)
          icon: 'info',
          confirmButtonColor: '#c8a209' // Set the background color to warning
        })
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  return (
    <div className='App'>
      <Suspense fallback={<FullScreenLoader />}>
        <Routes>
          {/* frontend */}
          <Route element={<FrontEndLayout />}>
            <Route path='/' element={<HomeScreen />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/ship' element={<Ship />} />
            <Route path='/order/payment/success' element={<ShipPaymentSuccess />} />
            <Route path='/get-quote' element={<GetQuote />} />
            <Route path='/register/user' element={<RegisterPageUser />} />
            <Route path='/user/verify-email' element={<VerifyEmail />} />
            <Route path='/login/user' element={<LoginPageUser />} />
            <Route path='/forgot/password' element={<ForgotPassword />} />
            {/* <Route path='/login/driver' element={<LoginPageDriver />} /> */}
            <Route path='/track-order' element={<TrackMyOrder />} />
            <Route path='/service-news' element={<ServiceNews />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/locations' element={<Locations />} />
            <Route
              path='/location-within-country/:locationId'
              element={<LocationWithinCountry />}
            />
            <Route path='/report-fraud' element={<ReportFraud />} />
            <Route
              path='/conditions-of-carriage'
              element={<ConditionsOfCarriage />}
            />

            {/* General protected routes frontend */}
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[
                    'User',
                    'Driver',
                    'Admin',
                    'SuperAdmin',
                    'Auditor',
                    'Manager',
                    'WarehouseAgent',
                    'Partner',
                    'Shipper',
                    'Carrier'
                  ]}
                />
              }
            >
              <Route path='/account' element={<MyAccount />} />
            </Route>

            {/* protected routes frontend */}
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[
                    'User',
                    'Driver',
                    'Admin',
                    'SuperAdmin',
                    'Auditor',
                    'Manager',
                    'WarehouseAgent',
                    'Partner',
                    'Shipper',
                    'Carrier'
                  ]}
                />
              }
            >
              <Route path='/my-orders' element={<MyOrders />} />
              <Route
                path='/order-details/:orderId'
                element={<OrderDetails />}
              />
              <Route
                path='/order/review-order/:orderId'
                element={<ReviewOrder />}
              />
            </Route>
          </Route>

          {/* General backend protected routes*/}
          <Route element={<BackEndLayout />}>
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[
                    'User',
                    'Driver',
                    'Admin',
                    'SuperAdmin',
                    'Auditor',
                    'Manager',
                    'WarehouseAgent',
                    'Partner',
                    'Shipper', 
                    'Carrier'
                  ]} 
                />
              }
            > 
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/scan-barcode' element={<ScanBarcode />} />
              <Route path='/barcode/order-details/:orderId' element={<OrderDetailsBarcode />} />
              <Route path='/barcode/depot-inventory-details/:depotInventoryId' element={<DepotInventoryDetailsBarcode />} />
              <Route path='/barcode/return-details/:returnId' element={<ReturnDetailsBarcode />} />
              <Route path='/barcode/outbound-details/:outboundId' element={<OutboundDetailsBarcode />} />
            </Route>
          </Route>

          {/* Driver backend protected routes*/}
          <Route element={<BackEndLayout />}>
            <Route element={<ProtectedRoute allowedRoles={['Driver']} />}>
              <Route path='/my/orders/driver' element={<MyOrdersDriver />} />
              <Route
                path='/orders/driver/order-details/:orderId'
                element={<OrderDetailsDriver />}
              />
              <Route
                path='/orders/driver/start-trip/:orderId'
                element={<OrderStartTripDriver />}
              />
            </Route>
          </Route>

          {/* WarehouseAgent, SuperAdmin & Admin backend protected routes*/}
          <Route element={<BackEndLayout />}>
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={['WarehouseAgent', 'Admin', 'SuperAdmin']}
                />
              }
            >
              <Route
                path='/all/order-inventories'
                element={<AllOrderInventories />}
              />
              <Route
                path='/all/depot/inventory'
                element={<AllDepotInventory />}
              />
              <Route path='/all/inbound' element={<AllInbound />} />
              <Route path='/all/outbound' element={<AllOutbound />} />
              <Route path='/all/return' element={<AllReturns />} />
              <Route path='/add/return' element={<AddReturn />} />
              <Route path='/returns/edit' element={<EditReturn />} />
              <Route path='/outbound/edit' element={<EditOutbound />} />
              <Route path='/inbound/edit' element={<EditInbound />} />
            </Route>
          </Route>

          {/* SuperAdmin, Admin backend protected routes*/}
          <Route element={<BackEndLayout />}>
            <Route
              element={
                <ProtectedRoute allowedRoles={['Admin', 'SuperAdmin']} />
              }
            >
              <Route path='/register/driver' element={<RegisterPageDriver />} />
              <Route
                path='/register/warehouse-agent'
                element={<RegisterPageWarehouseAgent />}
              />
              <Route path='/all/partners' element={<AllPartners />} />
              <Route path='/add/partner' element={<AddPartner />} />
              <Route path='/edit/partner' element={<EditPartner />} />
              <Route path='/all/users' element={<AllUsers />} />
              <Route
                path='/all/warehouse-agents'
                element={<AllWarehouseAgents />}
              />
              <Route path='/all/admin-users' element={<AllAdminUsers />} />
              <Route path='/all/drivers' element={<AllDrivers />} />
              <Route path='/edit/user' element={<EditUser />} />
              <Route path='/all/subscribers' element={<AllSubscribers />} />
              <Route
                path='/all/contact-messages'
                element={<AllContactMessages />}
              />
              <Route path='/all/faq' element={<AllFaq />} />
              <Route
                path='/all/faq/faq-categories'
                element={<AllFaqCategories />}
              />
              <Route
                path='/add/faq/faq-category'
                element={<AddFaqCategory />}
              />
              <Route path='/add/faq' element={<AddFaq />} />
              <Route
                path='/all/service-news/service-news-categories'
                element={<AllServiceNewsCategories />}
              />
              <Route path='/all/service-news' element={<AllServiceNews />} />
              <Route
                path='/add/service-news/service-news-category'
                element={<AddServiceNewsCategory />}
              />
              <Route path='/add/service-news' element={<AddServiceNews />} />
              <Route path='/all/locations' element={<AllLocation />} />
              <Route
                path='/all/locations/continents'
                element={<AllContinent />}
              />
              <Route
                path='/add/location/continent'
                element={<AddContinent />}
              />
              <Route path='/add/location' element={<AddLocation />} />
              <Route path='/all/events' element={<AllEvent />} />
              <Route path='/add/event' element={<AddEvent />} />
              <Route path='/all/slider' element={<AllSlider />} />
              <Route path='/add/slider' element={<AddSlider />} />
              <Route path='/all/carrier' element={<AllCarrier />} />
              <Route path='/add/carrier' element={<AddCarrier />} />
              <Route path='/all/depot' element={<AllDepot />} />
              <Route path='/add/depot' element={<AddDepot />} />
              <Route
                path='/add/depot/inventory'
                element={<AddDepotInventory />}
              />
              <Route path='/all/shipper' element={<AllShipper />} />
              <Route path='/add/shipper' element={<AddShipper />} />
              <Route path='/all/quotes' element={<AllQuotes />} />
              <Route path='/edit/quote/:quoteId' element={<EditQuote />} />
              <Route path='/all/vehicles' element={<AllVehicles />} />
              <Route path='/add/vehicle' element={<AddVehicle />} />
              <Route path='/all/orders' element={<AllOrders />} />
              <Route
                path='/orders/admin/edit-order/:orderId'
                element={<EditOrder />}
              />
              <Route
                path='/orders/admin/order-details/:orderId'
                element={<AdminOrderDetails />}
              />
              <Route
                path='/orders/admin/assign-driver/:orderId'
                element={<AdminAssignDriver />}
              />
              <Route path='/all/reviews' element={<AllReview />} />
              <Route path='/all/routes' element={<AllRoutes />} />
              <Route
                path='/routes/admin/route-details/:routeId'
                element={<RouteDetails />}
              />
            </Route>
          </Route>

          {/* 404 page */}
          <Route path='/404' element={<ErrorPage />} />

          {/* unauthorized */}
          <Route element={<FrontEndLayout />}>
            <Route path='/unauthorized' element={<UnauthorizedPage />} />
          </Route>

          {/* Catch-all route for unmatched paths, you can either use a 404 page or so */}
          <Route path='*' element={<Navigate to='/404' replace />} />
        </Routes>
        <BackToTopButton />
      </Suspense>
    </div>
  )
}

export default App
